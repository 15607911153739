<template>
    <div class="p-6 text-sm font-medium text-gray-900">
        <div v-if="vatReturn" class="flex items-center gap-2.5">
            <base-badge :label="$t('general.finalised')" variant="success" />
            <span class="text-sm font-normal text-gray-500">
                {{
                    $filters.dateCustomFormat(
                        vatReturn.created_at,
                        'D MMM YYYY, h:mm a'
                    )
                }}
            </span>
        </div>
        <p v-else>
            {{ company.company_name }}
        </p>
        <hr class="my-6 border-gray-200" />

        <div class="flex flex-col gap-6">
            <p>{{ $t('reportsVatReturns.vatCalculations') }}</p>
            <div class="flex flex-col gap-3.5">
                <template v-for="box in vatCalculationBoxes" :key="box.key">
                    <reports-vat-box
                        v-if="box.value.slug === 'box-3'"
                        :box="box.value"
                        :price="box3Amount"
                        :description="box.value.description"
                    />

                    <reports-vat-box
                        v-else-if="box.value.slug === 'box-5'"
                        :box="box.value"
                        :price="Math.abs(box5Amount)"
                    >
                        <template #description>
                            <strong class="font-semibold">
                                {{
                                    $t('reportsVatReturns.boxes.box5', {
                                        amount: box5Amount,
                                    })
                                }}
                            </strong>
                        </template>
                    </reports-vat-box>

                    <reports-vat-box
                        v-else-if="box.value.adjustable"
                        :box="box.value"
                        :description="box.value.description"
                        :price="box.value.amount"
                        :show-adjust="box.value.adjustable && !vatReturn"
                        @on-click-adjust="onClickAdjust(box)"
                    />
                    <reports-vat-box
                        v-else
                        :box="box.value"
                        :price="box.value.amount"
                    >
                        <template #description>
                            <strong class="font-semibold" v-if="box.value.bold">
                                {{ box.value.description }}
                            </strong>
                            <span class="font-normal text-gray-700" v-else>
                                {{ box.value.description }}
                            </span>
                        </template>
                    </reports-vat-box>
                </template>
            </div>

            <div class="flex flex-col gap-6">
                <p>{{ $t('reportsVatReturns.salesAndPurchases') }}</p>

                <div class="flex flex-col gap-3.5">
                    <template v-for="box in salesPurchasesBoxes" :key="box.key">
                        <reports-vat-box
                            v-if="box.value.adjustable"
                            :box="box.value"
                            :description="box.value.description"
                            :price="box.value.amount"
                            :show-adjust="box.value.adjustable && !vatReturn"
                            @on-click-adjust="onClickAdjust(box)"
                        />
                        <reports-vat-box
                            v-else
                            :box="box.value"
                            :price="box.value.amount"
                        >
                            <template #description>
                                <strong
                                    class="font-semibold"
                                    v-if="box.value.bold"
                                >
                                    {{ box.value.description }}
                                </strong>
                                <span class="font-normal text-gray-700" v-else>
                                    {{ box.value.description }}
                                </span>
                            </template>
                        </reports-vat-box>
                    </template>
                </div>
            </div>

            <div class="flex flex-col gap-6">
                <p>{{ $t('reportsVatReturns.suppliesAndPurchases') }}</p>

                <template v-for="box in suppliesPurchasesBoxes" :key="box.key">
                    <reports-vat-box
                        v-if="box.value.adjustable"
                        :box="box.value"
                        :description="box.value.description"
                        :price="box.value.amount"
                        :show-adjust="box.value.adjustable && !vatReturn"
                        @on-click-adjust="onClickAdjust(box)"
                    />
                    <reports-vat-box
                        v-else
                        :box="box.value"
                        :price="box.value.amount"
                    >
                        <template #description>
                            <strong class="font-semibold" v-if="box.value.bold">
                                {{ box.value.description }}
                            </strong>
                            <span class="font-normal text-gray-700" v-else>
                                {{ box.value.description }}
                            </span>
                        </template>
                    </reports-vat-box>
                </template>
            </div>
        </div>

        <hr class="my-10 border-gray-200" />

        <div class="flex justify-end pb-6">
            <base-button
                v-if="vatReturn"
                :loading="loadingDelete"
                variant="danger"
                @click="onDelete"
            >
                {{ $t('reportsVatReturns.deleteVATReturn') }}
            </base-button>
            <base-button v-else :loading="loadingFinalise" @click="onFinalise">
                {{ $t('reportsVatReturns.finaliseVATReturn') }}
            </base-button>
        </div>
    </div>

    <reports-vat-returns-view-box-finalise-modal
        v-if="vatReturn"
        :vat-return="vatReturn"
        :show="state.showFinaliseModal"
        @modal-close="toggleFinaliseModal"
    />

    <reports-vat-adjust-modal
        :show="!!state.adjustBox"
        :box="state.adjustBox?.value"
        :end-date="endDate"
        @modal-close="onCloseAdjust"
        @on-adjust-server="onAdjustServer"
        @on-adjust="onAdjust"
    />
</template>
<script setup>
import { VAT_BOX_TYPE } from '@tenant/modules/tenant/reports-vat-return/utils/constants'
import BigNumber from 'bignumber.js'
import { replaceQuery } from '@tenant/utils/helper'

const props = defineProps({
    vatReturn: {
        type: Object,
        required: false,
    },
    report: {
        type: Object,
        required: true,
    },
    startDate: {
        type: String,
        required: true,
    },
    endDate: {
        type: String,
        required: true,
    },
})

const emitter = useEmitter()
const route = useRoute()
const router = useRouter()
const { company } = useAuth()

const state = reactive({
    showFinaliseModal: false,
    adjustBox: null,
})

const boxes = computed(() => {
    return props.report.boxes.map((box) => {
        return {
            amount: box.net_amount || box.vat_amount || 0,
            reason: null,
            vat_box_id: box.id,
            ...box,
        }
    })
})

const { execute: executeFinalise, loading: loadingFinalise } = useApi(
    '/api/finalise-vat-return',
    'POST'
)

const { execute: executeDelete, loading: loadingDelete } = useApi(
    '/api/vat-returns/{id}',
    'DELETE'
)

const { handleSubmit } = useForm({
    initialValues: {
        boxes: boxes.value,
    },
})

const { fields, update } = useFieldArray('boxes')

watch(
    () => route.query.just_created,
    (value) => {
        if (!value) {
            return
        }

        const { just_created, ...restQuery } = route.query

        if (just_created) {
            state.showFinaliseModal = true
        }

        replaceQuery(restQuery)
    },
    { immediate: true }
)

const box3Amount = computed(() => {
    const box1Field = fields.value.find((i) => i.value.slug === 'box-1')
    const box2Field = fields.value.find((i) => i.value.slug === 'box-2')

    return new BigNumber(box1Field.value.amount)
        .plus(box2Field.value.amount)
        .toNumber()
})

const box5Amount = computed(() => {
    const box4Field = fields.value.find((i) => i.value.slug === 'box-4')

    return new BigNumber(box4Field.value.amount)
        .minus(box3Amount.value)
        .toNumber()
})

const vatCalculationBoxes = computed(() => {
    return fields.value.filter(
        (i) => i.value.type === VAT_BOX_TYPE.VAT_CALCULATIONS
    )
})

const salesPurchasesBoxes = computed(() => {
    return fields.value.filter(
        (i) => i.value.type === VAT_BOX_TYPE.SALES_PURCHASES
    )
})

const suppliesPurchasesBoxes = computed(() => {
    return fields.value.filter(
        (i) => i.value.type === VAT_BOX_TYPE.SUPPLIES_PURCHASES
    )
})

const onAdjust = ({ amount, reason }) => {
    const boxField = state.adjustBox

    update(boxField.key, {
        ...boxField.value,
        amount,
        reason,
    })

    onCloseAdjust()
}

const onAdjustServer = () => {
    emitter.emit('set-reload')
}

const toggleFinaliseModal = () => {
    state.showFinaliseModal = !state.showFinaliseModal
}

const onClickAdjust = (box) => {
    state.adjustBox = box
}

const onCloseAdjust = () => {
    state.adjustBox = null
}

const onFinalise = handleSubmit((values) => {
    executeFinalise({
        data: {
            start_date: props.startDate,
            end_date: props.endDate,
            boxes: values.boxes,
        },
    }).then((item) => {
        router.push({
            name: 'reports.vat-returns.review.box',
            params: {
                id: item.id,
            },
            query: {
                just_created: true,
            },
        })
    })
})

const onDelete = () => {
    executeDelete({
        params: {
            id: props.vatReturn.id,
        },
    }).then(() => {
        router.push({ name: 'reports.vat-returns.list' })
    })
}
</script>
