<template>
    <reports-vat-returns-heading>
        <div class="flex flex-col gap-16 p-6">
            <div class="flex flex-col">
                <p class="mb-6 text-md font-medium text-gray-900">
                    {{ $t('reportsVatReturns.selectDateRange') }}
                </p>
                <div
                    class="flex flex-1 items-start justify-between rounded-md border border-gray-300 bg-white p-6"
                >
                    <div class="flex flex-1 flex-col">
                        <form-date-picker
                            v-model="startDate"
                            :label="$t('reportsVatReturns.startDate')"
                            single
                            auto-apply
                            full-width
                        />
                    </div>
                    <span class="mx-3.5 mt-10">
                        {{ $t('reportsVatReturns.to') }}
                    </span>
                    <div class="flex flex-1 flex-col">
                        <form-date-picker
                            v-model="endDate"
                            :label="$t('reportsVatReturns.endDate')"
                            single
                            auto-apply
                            full-width
                        />
                    </div>
                    <div class="mt-8 flex flex-1 justify-end">
                        <base-button
                            :disabled="invalidSelectDate"
                            @click="createVatReturn"
                        >
                            {{ $t('reportsVatReturns.createVatReturn') }}
                        </base-button>
                    </div>
                </div>
            </div>

            <div>
                <template v-if="isFetched">
                    <p class="mb-6 text-md font-medium text-gray-900">
                        {{ $t('general.completed') }}
                    </p>
                    <div v-if="data.length > 0" class="flex flex-col gap-6">
                        <div
                            v-for="vatReturn in data"
                            :key="vatReturn.id"
                            class="flex flex-1 justify-between gap-2 rounded-md border border-gray-300 bg-white p-6"
                        >
                            <div class="flex flex-col gap-1">
                                <div class="flex items-center gap-2.5">
                                    <span
                                        class="text-sm font-medium text-gray-900"
                                    >
                                        {{
                                            $filters.dateCustomFormat(
                                                vatReturn.start_date,
                                                'D MMM YYYY'
                                            )
                                        }}
                                        -
                                        {{
                                            $filters.dateCustomFormat(
                                                vatReturn.end_date,
                                                'D MMM YYYY'
                                            )
                                        }}
                                    </span>
                                    <base-badge
                                        variant="gray"
                                        :label="$t('general.finalised')"
                                    />
                                </div>
                                <p class="text-sm font-normal text-gray-500">
                                    {{
                                        $t('reportsVatReturns.submittedBy', {
                                            date: $filters.dateCustomFormat(
                                                vatReturn.created_at,
                                                'D MMM YYYY'
                                            ),
                                            author: vatReturn.author.full_name,
                                        })
                                    }}
                                </p>
                            </div>
                            <div class="flex items-center gap-5">
                                <span class="text-sm font-normal text-gray-500">
                                    <template
                                        v-if="
                                            getVatToPayAmount(vatReturn.boxes) >
                                            0
                                        "
                                    >
                                        {{
                                            $t(
                                                'reportsVatReturns.vatAmountRefundable'
                                            )
                                        }}
                                    </template>
                                    <template v-else>
                                        {{
                                            $t('reportsVatReturns.vatAmountDue')
                                        }}
                                    </template>
                                </span>
                                <span class="text-sm font-medium text-gray-900">
                                    {{ formatAmount(vatReturn.amount) }}
                                </span>
                                <base-button
                                    size="sm"
                                    variant="default"
                                    @click="onReview(vatReturn)"
                                >
                                    {{ $t('general.review') }}
                                </base-button>
                            </div>
                        </div>
                    </div>

                    <div
                        v-else
                        class="flex flex-1 items-center justify-center rounded-md border border-gray-300 bg-white p-6"
                    >
                        <p class="py-16 text-md font-medium text-gray-900">
                            {{
                                $t(
                                    'reportsVatReturns.finalizedVatReturnsWillBe'
                                )
                            }}
                        </p>
                    </div>
                </template>

                <base-loading
                    v-if="isFetching"
                    class="items-center justify-center pt-6"
                />
            </div>
        </div>
    </reports-vat-returns-heading>
</template>

<script setup>
import { dayDiff } from '@tenant/utils/day'
import { useReportsVatReturns } from '@tenant/modules/tenant/reports-vat-return/composables/use-reports-vat-return'
import BigNumber from 'bignumber.js'
import {
    formatAmount,
    getVatReturnBox,
} from '@tenant/modules/tenant/reports-vat-return/utils/helper'

const emitter = useEmitter()
const router = useRouter()

const startDate = ref(null)
const endDate = ref(null)

const { data, onLoadMore, isFetching, isFetched } = useReportsVatReturns()

onMounted(() => {
    emitter.on('scroll-end', onLoadMore)
})

onUnmounted(() => {
    emitter.off('scroll-end', onLoadMore)
})

const invalidSelectDate = computed(() => {
    if (!startDate.value || !endDate.value) {
        return true
    }

    return dayDiff(startDate.value, endDate.value) > 0
})

const createVatReturn = () => {
    router.push({
        name: 'reports.vat-returns.view.box',
        params: {
            date: btoa(JSON.stringify([startDate.value, endDate.value])),
        },
    })
}

const onReview = (vatReturn) => {
    router.push({
        name: 'reports.vat-returns.review.box',
        params: {
            id: vatReturn.id,
        },
    })
}

const getVatToPayAmount = (boxes) => {
    const vatReturnBox4 = getVatReturnBox(boxes, 'box-4')
    const vatReturnBox2 = getVatReturnBox(boxes, 'box-2')
    const vatReturnBox1 = getVatReturnBox(boxes, 'box-1')

    return new BigNumber(vatReturnBox4.amount || 0)
        .minus(vatReturnBox1.amount || 0)
        .minus(vatReturnBox2.amount || 0)
        .toNumber()
}
</script>
