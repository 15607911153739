<template>
    <reports-vat-returns-heading>
        <div class="flex flex-col gap-6 p-6">
            <base-loading v-if="loading" class="items-center justify-center" />
            <div
                class="rounded-lg border border-gray-200 bg-white"
                v-else-if="result"
            >
                <base-tabs
                    variant="horizontal"
                    :items="tabs"
                    item-class="pt-6"
                    tab-style="underlined"
                    :has-margin="false"
                    tab-class="px-6 border-b border-gray-200"
                >
                    <template #tab-item="{ item }">
                        <span class="pb-1.5 pt-3">{{ item.label }}</span>
                    </template>

                    <template #content>
                        <router-view
                            :report="result"
                            :start-date="startDate"
                            :end-date="endDate"
                        />
                    </template>
                </base-tabs>
            </div>
        </div>
    </reports-vat-returns-heading>
</template>
<script setup>
const { t } = useI18n()
const route = useRoute()
const { execute, loading, result } = useApi('/api/view-vat-return', 'POST')

const dates = computed(() => {
    if (route.params.date) {
        return JSON.parse(atob(route.params.date))
    }

    return null
})
const startDate = computed(() => {
    return dates.value[0]
})
const endDate = computed(() => {
    return dates.value[1]
})

const tabs = computed(() => [
    {
        label: t('reportsVatReturns.tabs.vatReturn'),
        route: 'reports.vat-returns.view.box',
    },
    {
        label: t('reportsVatReturns.tabs.boxTransactions'),
        route: 'reports.vat-returns.view.box-transactions',
    },
    {
        label: t('reportsVatReturns.tabs.rateTransactions'),
        route: 'reports.vat-returns.view.rate-transactions',
    },
])

onMounted(() => {
    execute({
        data: {
            start_date: startDate.value,
            end_date: endDate.value,
        },
    })
})
</script>
