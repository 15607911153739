<template>
    <base-modal
        :show="show"
        size="lg"
        close
        :loading="loading"
        @modal-close="closeModal"
    >
        <template #modal-header>
            <h5 class="text-lg font-medium text-gray-900">
                {{ $t('reportsVatReturns.vatReturnHasBeenFinalised') }}
            </h5>
        </template>
        <template #content>
            <div class="px-6 pb-8 pt-2">
                <p class="text-sm font-normal text-gray-500">
                    {{ $t('reportsVatReturns.youWillNeedToSubmitTheVat') }}
                </p>
            </div>
        </template>
        <template #modal-footer>
            <div class="flex gap-3">
                <base-button
                    variant="default"
                    full-width
                    :disabled="loading"
                    @click="closeModal"
                >
                    {{ $t('reportsVatReturns.backToVatReturns') }}
                </base-button>
                <base-button
                    v-if="vatToPayAmount > 0 && $acl.can('create_invoices')"
                    variant="primary"
                    full-width
                    :loading="loading"
                    @click="onCreateDraftInvoice"
                >
                    {{ $t('reportsVatReturns.createDraftInvoiceForReturn') }}
                </base-button>
                <base-button
                    v-if="vatToPayAmount <= 0 && $acl.can('create_bills')"
                    variant="primary"
                    full-width
                    :loading="loading"
                    @click="onCreateDraftBill"
                >
                    {{ $t('reportsVatReturns.createDraftBillForReturn') }}
                </base-button>
            </div>
        </template>
    </base-modal>
</template>
<script setup>
import BigNumber from 'bignumber.js'
import { getVatReturnBox } from '@tenant/modules/tenant/reports-vat-return/utils/helper'

const emit = defineEmits(['modal-close'])

const props = defineProps({
    show: {
        type: [Boolean, Number, String],
        default: false,
    },
    vatReturn: {
        type: Object,
        required: true,
    },
})

const router = useRouter()

const { execute: executeDraftInvoice, loading: loadingDraftInvoice } = useApi(
    '/api/vat-returns/{id}/create-draft-invoice',
    'POST'
)

const { execute: executeDraftBill, loading: loadingDraftBill } = useApi(
    '/api/vat-returns/{id}/create-draft-bill',
    'POST'
)

const loading = computed(
    () => loadingDraftBill.value || loadingDraftInvoice.value
)

const boxes = computed(() => props.vatReturn.boxes)

const vatReturnBox1 = computed(() => getVatReturnBox(boxes.value, 'box-1'))
const vatReturnBox2 = computed(() => getVatReturnBox(boxes.value, 'box-2'))
const vatReturnBox4 = computed(() => getVatReturnBox(boxes.value, 'box-4'))

const vatToPayAmount = computed(() =>
    new BigNumber(vatReturnBox4.value.amount || 0)
        .minus(vatReturnBox1.value.amount || 0)
        .minus(vatReturnBox2.value.amount || 0)
        .toNumber()
)

const closeModal = () => {
    emit('modal-close')
}

const onCreateDraftInvoice = () => {
    executeDraftInvoice({
        params: {
            id: props.vatReturn.id,
        },
    }).then((bill) => {
        const linkRoute = router.resolve({
            name: 'invoices.edit',
            params: {
                id: bill.id,
            },
        })

        window.open(linkRoute.href, '_blank')
        closeModal()
    })
}

const onCreateDraftBill = () => {
    executeDraftBill({
        params: {
            id: props.vatReturn.id,
        },
    }).then((bill) => {
        const linkRoute = router.resolve({
            name: 'purchases.bill.edit',
            params: {
                id: bill.id,
            },
        })

        window.open(linkRoute.href, '_blank')
        closeModal()
    })
}
</script>
