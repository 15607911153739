import { isActive } from '@tenant/core/feature'

const navigation = isActive('FEATURE_FLAG_1374')
    ? [
          {
              id: 'sas-vat-returns',
              path: 'reports.vat-returns',
              label: 'reportsVatReturns.sidebar.label',
              parent: 'sas-reports',
              position: 9999,
              permission: 'view_reports',
          },
      ]
    : []

export default navigation
