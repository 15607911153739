import { numberFormat } from '@tenant/utils/helper'

export const getVatReturnBox = (boxes, boxSlug) => {
    return boxes.find((i) => i.box.slug === boxSlug)
}

export const formatAmount = (amount) => {
    if (!amount) {
        return numberFormat(0)
    }

    const formattedPrice = numberFormat(Math.abs(amount))

    return amount > 0 ? formattedPrice : `(${formattedPrice})`
}
