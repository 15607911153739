import { useInfiniteQuery } from '@tanstack/vue-query'
import { uniqBy } from 'lodash-es'

export const useReportsVatReturns = () => {
    const { execute } = useApi('/api/vat-returns', 'GET')

    const {
        data,
        isFetching,
        isFetched,
        fetchNextPage: fetchMore,
        hasNextPage: hasMore,
    } = useInfiniteQuery({
        queryKey: ['vat-return'],
        queryFn: ({ pageParam }) => {
            return execute({
                queries: {
                    cursor: pageParam,
                    limit: 20,
                },
            })
        },
        getNextPageParam: ({ next_cursor }) => next_cursor,
        select: (res) =>
            uniqBy(
                res?.pages?.flatMap((x) => x?.data),
                (x) => x?.id
            ),
        refetchOnWindowFocus: false,
        refetchInterval: false,
    })

    const onLoadMore = () => {
        if (hasMore.value) {
            fetchMore()
        }
    }

    return {
        onLoadMore,
        isFetched,
        data,
        isFetching,
    }
}
