export default {
    reportsVatReturns: {
        sidebar: {
            label: 'VAT Returns',
        },

        title: 'VAT Returns',
        signUpTaxForAll: 'Sign up for Tax for all',
        selectDateRange: 'Select a date range to create a VAT return',
        finalizedVatReturnsWillBe: 'Finalised VAT returns will be shown here',
        startDate: 'Start date',
        to: 'to',
        endDate: 'End date',
        createVatReturn: 'Create VAT return',
        deleteVATReturn: 'Delete VAT Return',
        finaliseVATReturn: 'Finalise VAT return',
        viewDetailedUpdates: 'View detailed updates ({num})',
        submittedBy: 'Submitted {date} by {author}',
        vatAmountDue: 'VAT amount due',
        vatAmountRefundable: 'VAT amount refundable',
        totalVat: 'Total {vat}',
        vatReturnHasBeenFinalised: 'VAT Return has been finalised',
        youWillNeedToSubmitTheVat:
            'You will need to submit the VAT Return to Tax Department outside of Cybooks.',
        backToVatReturns: 'Back to VAT Returns',
        createDraftBillForReturn: 'Create draft bill for return',
        createDraftInvoiceForReturn: 'Create draft invoice for return',

        boxes: {
            box5: ({ named }) => {
                const amount = named('amount')

                return amount > 0
                    ? 'VAT to Reclaim from TaxDepartment'
                    : 'VAT to pay to Tax Department'
            },
        },

        tabs: {
            vatReturn: 'VAT Return',
            boxTransactions: 'Transactions by VAT box',
            rateTransactions: 'Transactions by tax rate',
        },

        grid: {
            date: 'Date',
            account: 'Account',
            reference: 'Reference',
            details: 'Details',
            gross: 'Gross',
            vat: 'VAT',
            net: 'Net',
        },

        adjustmentAmount: 'Adjustment Amount',
        reasonForAdjustment: 'Reason for adjustment',
        adjustModalTitle: '{name} adjustment',
        adjustModalDescription:
            "Manual adjustments will make changes to your accounts and VAT return amount. If you're not sure about making adjustments to your VAT Return, contact a Cybooks-certified advisor.",
        vatCalculations: 'VAT Calculations',
        salesAndPurchases: 'Sales and Purchases Excluding VAT',
        suppliesAndPurchases: 'Supplies and Purchases Excluding VAT',
        createAccountingTransaction: 'Create accounting transaction',
        anAccountingTransactionWillBeCreated:
            'An accounting transaction will be created for this adjustment.',
        adjustmentAccountMustBeSelected: 'Adjustment account must be selected',
        taxRateMustBeSelected: 'Tax rate must be selected',
    },
}
