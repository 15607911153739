<template>
    <base-modal close @modal-close="closeModal" :show="show" size="sxl">
        <template #modal-header>
            <h5 class="text-lg font-medium text-gray-900">
                {{
                    $t('reportsVatReturns.adjustModalTitle', {
                        name: box?.name,
                    })
                }}
            </h5>
        </template>
        <div class="flex flex-col gap-5">
            <p class="text-sm font-normal text-gray-500">
                {{ $t('reportsVatReturns.adjustModalDescription') }}
            </p>
            <form-number-input
                v-model="amount"
                label="reportsVatReturns.adjustmentAmount"
                :precision="2"
                :error-message="amountError"
            />
            <form-textarea
                v-model="reason"
                label="reportsVatReturns.reasonForAdjustment"
                :placeholder="$t('general.writeSomething')"
                rows="5"
            />
            <div class="flex flex-col gap-2.5">
                <form-checkbox
                    v-model="withTransaction"
                    :text="$t('reportsVatReturns.createAccountingTransaction')"
                />
                <span class="text-sm font-normal text-gray-700">
                    {{
                        $t(
                            'reportsVatReturns.anAccountingTransactionWillBeCreated'
                        )
                    }}
                </span>

                <div v-if="withTransaction" class="flex gap-2.5">
                    <form-detail-chart-of-account
                        v-model="chartOfAccountId"
                        :label="$t('general.account')"
                        :hint-text="
                            $t(
                                'reportsVatReturns.adjustmentAccountMustBeSelected'
                            )
                        "
                        :placeholder="$t('general.selectAccount')"
                        :can-create="false"
                        :include-modal="false"
                    />
                    <form-single-select
                        v-model="taxRuleId"
                        :label="$t('general.taxRate')"
                        :hint-text="
                            $t('reportsVatReturns.taxRateMustBeSelected')
                        "
                        :placeholder="$t('general.selectTaxRate')"
                        :options="taxRules"
                        option-value="id"
                        option-label="name"
                    />
                </div>
            </div>
        </div>
        <template #modal-footer>
            <div class="flex justify-between gap-3">
                <base-button
                    outline
                    full-width
                    size="sm"
                    variant="default"
                    @click="closeModal"
                >
                    {{ $t('general.cancel') }}
                </base-button>
                <base-button
                    full-width
                    size="sm"
                    :disabled="disabled"
                    @click="onUpdate"
                >
                    {{ $t('general.update') }}
                </base-button>
            </div>
        </template>
    </base-modal>
</template>

<script setup>
import { empty } from '@tenant/utils/helper'

const emit = defineEmits(['modal-close', 'on-adjust'])

const props = defineProps({
    box: {
        type: Object,
        default: null,
    },
    endDate: {
        type: String,
        required: true,
    },
    show: {
        type: Boolean,
        default: true,
    },
})

const { execute } = useApi('/api/vat-returns/adjustment', 'POST')

const { taxRules } = useTaxRules()
const { handleSubmit, resetForm, setErrors } = useForm()

const { value: amount, errorMessage: amountError } = useField('amount')
const { value: reason } = useField('reason')
const { value: withTransaction } = useField(
    'with_transaction',
    {},
    { initialValue: true }
)
const { value: chartOfAccountId } = useField('chart_of_account_id')
const { value: taxRuleId } = useField('tax_rule_id')

const disabled = computed(() => {
    if (empty(amount.value) || empty(reason.value)) {
        return true
    }

    if (withTransaction.value) {
        return empty(chartOfAccountId.value) || empty(taxRuleId.value)
    }

    return false
})

const closeModal = () => {
    resetForm()
    emit('modal-close')
}

const onUpdate = handleSubmit(async (values) => {
    try {
        if (withTransaction.value) {
            await execute({
                data: {
                    ...values,
                    end_date: props.endDate,
                    box_id: props.box.id,
                },
            })

            emit('on-adjust-server')
        } else {
            emit('on-adjust', {
                amount: parseFloat(amount.value),
                reason: reason.value,
            })
        }
    } catch ({ errors }) {
        setErrors(errors)
    }
})
</script>
