<template>
    <div class="pb-3.5">
        <p class="px-6 py-3.5 text-md font-medium text-gray-900">
            {{ $t('reportsVatReturns.tabs.boxTransactions') }}
        </p>
        <hr class="border-gray-200" />
        <div class="pt-3.5" v-for="box in boxTransactions" :key="box.id">
            <div class="flex items-center justify-between py-2.5 pl-6">
                <div class="flex items-center gap-2.5">
                    <base-badge
                        variant="gray"
                        :label="box.name"
                        class="whitespace-nowrap"
                    />
                    <p class="text-sm font-normal text-gray-700">
                        {{ box.description }}
                    </p>
                </div>
                <div class="flex text-sm font-semibold text-gray-900">
                    <span v-if="!isNil(box.vat_amount)" class="block w-88 px-6">
                        {{ formatAmount(box.vat_amount) }}
                    </span>
                    <span
                        v-else-if="!isNil(box.net_amount)"
                        class="block w-44 px-6"
                    >
                        {{ formatAmount(box.net_amount) }}
                    </span>
                </div>
            </div>

            <div v-for="tax in box.taxes" :key="tax.id">
                <p class="px-6 py-3.5 text-sm font-medium text-gray-900">
                    {{ tax.name }}
                </p>

                <data-grid
                    :data-source="tax.trans || tax.reclaims_trans"
                    :columns="columns"
                    :table-borderless="true"
                    :border-table-header="true"
                >
                    <template #column-date="{ item }">
                        {{ $filters.dateHumanFormat(item.date) }}
                    </template>

                    <template #column-account="{ item }">
                        <span class="text-sm font-medium text-primary-600">
                            {{ item.account }}
                        </span>
                    </template>

                    <template #column-reference="{ item }">
                        <span
                            class="cursor-pointer text-sm font-medium text-primary-600"
                            @click="redirectToSource(item)"
                        >
                            {{ item.reference }}
                        </span>
                    </template>

                    <template #column-vat="{ item }">
                        {{ formatAmount(item.vat) }}
                    </template>

                    <template #column-net="{ item }">
                        {{ formatAmount(item.net) }}
                    </template>
                </data-grid>
            </div>
        </div>
    </div>
</template>
<script setup>
import { isNil } from 'lodash-es'
import { formatAmount } from '../utils/helper'

const props = defineProps({
    report: {
        type: Object,
        required: true,
    },
})

const router = useRouter()
const { t } = useI18n()

const boxTransactions = computed(() => props.report.box_transactions)

const columns = [
    {
        property: 'date',
        label: t('reportsVatReturns.grid.date'),
    },
    {
        property: 'account',
        label: t('reportsVatReturns.grid.account'),
    },
    {
        property: 'reference',
        label: t('reportsVatReturns.grid.reference'),
    },
    {
        property: 'details',
        label: t('reportsVatReturns.grid.details'),
    },
    {
        property: 'vat',
        label: t('reportsVatReturns.grid.vat'),
        headerCellClass: 'w-44',
    },
    {
        property: 'net',
        label: t('reportsVatReturns.grid.net'),
        headerCellClass: 'w-44',
    },
]

const redirectToSource = (trans) => {
    let routeName = null

    switch (trans.source_type) {
        case 'bill':
            routeName = 'purchases.bill.detail'
            break
        case 'expense':
            routeName = 'purchases.expense.detail'
            break
        case 'invoice':
            routeName = 'invoices.dispatch'
            break
        case 'estimate':
            routeName = 'estimates.dispatch'
            break
        case 'creditnote':
            routeName = 'credit-notes.dispatch'
            break
        default:
            break
    }

    if (!routeName) {
        return
    }

    router.push({
        name: routeName,
        params: {
            id: trans.source_id,
        },
    })
}
</script>
