<template>
    <base-sticky-heading content-class="bg-gray-50">
        <template #title>
            <strong
                class="flex items-center justify-center gap-2.5 text-xl font-medium text-gray-900"
            >
                {{ $t('reportsVatReturns.title') }}

                <slot name="dates">
                    <span
                        class="text-md font-normal text-gray-500"
                        v-if="dates"
                    >
                        {{
                            dates
                                .map((date) =>
                                    $filters.dateCustomFormat(
                                        date,
                                        'D MMM YYYY'
                                    )
                                )
                                .join(' - ')
                        }}
                    </span>
                </slot>
            </strong>
        </template>

        <template #right>
            <base-button variant="default" @click="signUp">
                {{ $t('reportsVatReturns.signUpTaxForAll') }}
            </base-button>
        </template>
        <div class="px-6 pt-6">
            <base-alert variant="warning">
                <span class="mb-1 text-sm font-medium text-warning-700">
                    Beta version
                </span>

                <p class="mb-3 text-sm font-normal text-warning-600">
                    We’re currently testing the VAT report on production,
                    however: The current version shouldn’t be used to submit a
                    VAT report. We’re working hard to roll out a production
                    ready version of the VAT report. We’ll inform all users once
                    the VAT report is ready for production use.
                </p>
                <div
                    class="flex cursor-pointer items-center gap-2 text-sm font-medium text-warning-700"
                    id="launch_messenger"
                >
                    Let us know feedback
                    <base-icon
                        name="line-icons:arrows:arrow-right"
                        variant="inherit"
                    />
                </div>
            </base-alert>

            <!--            <base-alert variant="gray" class="bg-white">-->
            <!--                <div class="mb-1 flex items-center gap-1">-->
            <!--                    <span class="text-sm font-medium text-gray-700">-->
            <!--                        Extension in Reporting Deadline for DAC7 Data Submission-->
            <!--                        2023-->
            <!--                    </span>-->
            <!--                    <base-badge-->
            <!--                        variant="success"-->
            <!--                        label="Updated 1st January 2023"-->
            <!--                    />-->
            <!--                </div>-->

            <!--                <p class="mb-3 text-sm font-normal text-gray-600">-->
            <!--                    The Cyprus Tax Department informs all the Reporting-->
            <!--                    Platforms Operators that the reporting deadline for 2023 for-->
            <!--                    DAC7 purposes is being extended to 16/2/2024. For more-->
            <!--                    information, visit the Tax Department website.-->
            <!--                </p>-->
            <!--                <div-->
            <!--                    class="flex cursor-pointer items-center gap-2 text-sm font-medium text-gray-500"-->
            <!--                >-->
            <!--                    {{-->
            <!--                        $t('reportsVatReturns.viewDetailedUpdates', {-->
            <!--                            num: 2,-->
            <!--                        })-->
            <!--                    }}-->
            <!--                    <base-icon-->
            <!--                        name="line-icons:arrows:arrow-right"-->
            <!--                        variant="inherit"-->
            <!--                    />-->
            <!--                </div>-->
            <!--            </base-alert>-->
        </div>

        <slot />
    </base-sticky-heading>
</template>

<script setup>
const { t } = useI18n()
const route = useRoute()

useHead({
    title: t('reportsVatReturns.title'),
})

const dates = computed(() => {
    if (route.params.date) {
        return JSON.parse(atob(route.params.date))
    }

    return null
})

const signUp = () => {
    window.open('https://taxforall.mof.gov.cy/CreateAccount', '_blank')
}
</script>
