<template>
    <div class="flex items-center justify-between gap-2">
        <div class="flex flex-1 items-center">
            <div class="w-20">
                <base-badge
                    variant="gray"
                    :label="box.name"
                    class="whitespace-nowrap"
                />
            </div>

            <slot name="description" v-bind="{ description }">
                <span class="font-normal text-gray-700">
                    {{ description }}
                </span>
            </slot>
        </div>
        <div class="flex items-center justify-end">
            <span
                class="cursor-pointer text-primary-700"
                v-if="showAdjust"
                @click="onShowAdjust"
            >
                {{ $t('general.adjust') }}
            </span>

            <p class="ml-1 min-w-28 text-right font-normal">
                {{ priceDisplay }}
            </p>
        </div>
    </div>
</template>

<script setup>
import { formatAmount } from '@tenant/modules/tenant/reports-vat-return/utils/helper'

const emit = defineEmits(['on-click-adjust'])

const props = defineProps({
    box: {
        type: Object,
        required: true,
    },
    description: {
        type: String,
        required: false,
    },
    price: {
        type: Number,
        required: false,
    },
    showAdjust: {
        type: Boolean,
        default: false,
    },
})

const priceDisplay = computed(() => formatAmount(props.price))

const onShowAdjust = () => {
    emit('on-click-adjust')
}
</script>
