import { GUARD } from '@tenant/utils/constants'
import { isActive } from '@tenant/core/feature'

const routes = isActive('FEATURE_FLAG_1374')
    ? [
          {
              name: 'reports.vat-returns',
              path: '/reports/vat-returns',
              redirect: {
                  name: 'reports.vat-returns.list',
              },
              meta: {
                  closeSidebar: true,
              },
              children: [
                  {
                      name: 'reports.vat-returns.list',
                      path: '',
                      component: () =>
                          import('./pages/reports-vat-returns-list.vue'),
                  },
                  {
                      name: 'reports.vat-returns.review',
                      path: 'review/:id',
                      component: () =>
                          import('./pages/reports-vat-returns-review.vue'),
                      children: [
                          {
                              name: 'reports.vat-returns.review.box',
                              path: 'box',
                              component: () =>
                                  import(
                                      './views/reports-vat-returns-view-box.vue'
                                  ),
                          },
                          {
                              name: 'reports.vat-returns.review.box-transactions',
                              path: 'box-transactions',
                              component: () =>
                                  import(
                                      './views/reports-vat-returns-view-box-transactions.vue'
                                  ),
                          },
                          {
                              name: 'reports.vat-returns.review.rate-transactions',
                              path: 'rate-transactions',
                              component: () =>
                                  import(
                                      './views/reports-vat-returns-view-rate-transactions.vue'
                                  ),
                          },
                      ],
                  },
                  {
                      name: 'reports.vat-returns.view',
                      path: 'view/:date',
                      component: () =>
                          import('./pages/reports-vat-returns-view.vue'),
                      children: [
                          {
                              name: 'reports.vat-returns.view.box',
                              path: 'box',
                              component: () =>
                                  import(
                                      './views/reports-vat-returns-view-box.vue'
                                  ),
                          },
                          {
                              name: 'reports.vat-returns.view.box-transactions',
                              path: 'box-transactions',
                              component: () =>
                                  import(
                                      './views/reports-vat-returns-view-box-transactions.vue'
                                  ),
                          },
                          {
                              name: 'reports.vat-returns.view.rate-transactions',
                              path: 'rate-transactions',
                              component: () =>
                                  import(
                                      './views/reports-vat-returns-view-rate-transactions.vue'
                                  ),
                          },
                      ],
                  },
              ],
          },
      ]
    : []

export default {
    routes,
    meta: {
        layout: 'dashboard-layout',
        guard: GUARD.TENANT,
        permission: 'view_reports',
    },
}
