<template>
    <div class="pb-3.5">
        <p class="px-6 py-3.5 text-md font-medium text-gray-900">
            {{ $t('reportsVatReturns.tabs.rateTransactions') }}
        </p>
        <hr class="border-gray-200" />
        <div class="pt-3.5" v-for="vat in vatTransactions" :key="vat.id">
            <p class="px-6 py-3.5 text-sm font-medium text-gray-900">
                {{ vat.name }}
            </p>

            <data-grid
                :data-source="vat.trans || vat.reclaims_trans"
                :columns="columns"
                :table-borderless="true"
                :border-table-header="true"
            >
                <template #column-date="{ item }">
                    {{ $filters.dateHumanFormat(item.date) }}
                </template>

                <template #column-account="{ item }">
                    <span class="text-sm font-medium text-primary-600">
                        {{ item.account }}
                    </span>
                </template>

                <template #column-reference="{ item }">
                    <span
                        class="cursor-pointer text-sm font-medium text-primary-600"
                        @click="redirectToSource(item)"
                    >
                        {{ item.reference }}
                    </span>
                </template>

                <template #column-gross="{ item }">
                    {{ formatAmount(item.gross) }}
                </template>

                <template #column-vat="{ item }">
                    {{ formatAmount(item.vat) }}
                </template>

                <template #column-net="{ item }">
                    {{ formatAmount(item.net) }}
                </template>
            </data-grid>

            <div class="flex items-center justify-between pl-6 pt-3.5">
                <span class="text-sm font-semibold text-gray-900">
                    {{ $t('reportsVatReturns.totalVat', { vat: vat.name }) }}
                </span>
                <div class="flex text-sm font-semibold text-gray-900">
                    <span class="block w-44 px-6">
                        {{ formatAmount(sumOf(vat, 'gross')) }}
                    </span>
                    <span class="block w-44 px-6">
                        {{ formatAmount(sumOf(vat, 'vat')) }}
                    </span>
                    <span class="block w-44 px-6">
                        {{ formatAmount(sumOf(vat, 'net')) }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { formatAmount } from '../utils/helper'

const props = defineProps({
    report: {
        type: Object,
        required: true,
    },
})

const router = useRouter()
const { t } = useI18n()

const vatTransactions = computed(() => props.report.vat_transactions)

const columns = [
    {
        property: 'date',
        label: t('reportsVatReturns.grid.date'),
    },
    {
        property: 'account',
        label: t('reportsVatReturns.grid.account'),
    },
    {
        property: 'reference',
        label: t('reportsVatReturns.grid.reference'),
    },
    {
        property: 'details',
        label: t('reportsVatReturns.grid.details'),
    },
    {
        property: 'gross',
        label: t('reportsVatReturns.grid.gross'),
        headerCellClass: 'w-44',
    },
    {
        property: 'vat',
        label: t('reportsVatReturns.grid.vat'),
        headerCellClass: 'w-44',
    },
    {
        property: 'net',
        label: t('reportsVatReturns.grid.net'),
        headerCellClass: 'w-44',
    },
]

const sumOf = (vat, field) => {
    return (vat.trans || vat.reclaims_trans || []).reduce(
        (cur, trans) => cur + trans[field],
        0.0
    )
}

const redirectToSource = (trans) => {
    let routeName = null

    switch (trans.source_type) {
        case 'bill':
            routeName = 'purchases.bill.detail'
            break
        case 'expense':
            routeName = 'purchases.expense.detail'
            break
        case 'invoice':
            routeName = 'invoices.dispatch'
            break
        case 'estimate':
            routeName = 'estimates.dispatch'
            break
        case 'creditnote':
            routeName = 'credit-notes.dispatch'
            break
        default:
            break
    }

    if (!routeName) {
        return
    }

    router.push({
        name: routeName,
        params: {
            id: trans.source_id,
        },
    })
}
</script>
