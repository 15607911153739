<template>
    <reports-vat-returns-heading>
        <template #dates>
            <span
                class="text-md font-normal text-gray-500"
                v-if="startDate && endDate"
            >
                {{
                    [startDate, endDate]
                        .map((date) =>
                            $filters.dateCustomFormat(date, 'D MMM YYYY')
                        )
                        .join(' - ')
                }}
            </span>
        </template>
        <div class="flex flex-col gap-6 p-6">
            <base-loading v-if="loading" class="items-center justify-center" />
            <div
                class="rounded-lg border border-gray-200 bg-white"
                v-else-if="report"
            >
                <base-tabs
                    variant="horizontal"
                    :items="tabs"
                    item-class="pt-6"
                    tab-style="underlined"
                    :has-margin="false"
                    tab-class="px-6 border-b border-gray-200"
                >
                    <template #tab-item="{ item }">
                        <span class="pb-1.5 pt-3">{{ item.label }}</span>
                    </template>

                    <template #content>
                        <router-view
                            :vat-return="vatReturn"
                            :report="report"
                            :start-date="startDate"
                            :end-date="endDate"
                        />
                    </template>
                </base-tabs>
            </div>
        </div>
    </reports-vat-returns-heading>
</template>
<script setup>
import { shortServerFormat } from '@tenant/utils/day'

const { t } = useI18n()

const route = useRoute()

const {
    execute: executeVatReturn,
    loading: loadingVatReturn,
    result: vatReturn,
} = useApi('/api/vat-returns/{id}', 'GET')
const {
    execute: executeViewVatReturn,
    loading: loadingViewVatReturn,
    result: resultViewVatReturn,
} = useApi('/api/view-vat-return', 'POST')

const loading = computed(() => {
    return loadingVatReturn.value || loadingViewVatReturn.value
})
const startDate = computed(() => {
    if (!vatReturn.value) {
        return null
    }

    return shortServerFormat(vatReturn.value.start_date)
})

const endDate = computed(() => {
    if (!vatReturn.value) {
        return null
    }

    return shortServerFormat(vatReturn.value.end_date)
})

const report = computed(() => {
    if (!vatReturn.value || !resultViewVatReturn.value) {
        return null
    }

    const vatReturnBoxes = vatReturn.value.boxes
    const boxes = resultViewVatReturn.value.boxes.map((viewVatReturn) => {
        const vatReturnBox = vatReturnBoxes.find(
            (i) => i.vat_box_id === viewVatReturn.id
        )

        return {
            ...viewVatReturn,
            amount: vatReturnBox.amount,
            reason: vatReturnBox.id,
        }
    })

    return {
        ...resultViewVatReturn.value,
        boxes,
    }
})

const tabs = computed(() => [
    {
        label: t('reportsVatReturns.tabs.vatReturn'),
        route: 'reports.vat-returns.review.box',
    },
    {
        label: t('reportsVatReturns.tabs.boxTransactions'),
        route: 'reports.vat-returns.review.box-transactions',
    },
    {
        label: t('reportsVatReturns.tabs.rateTransactions'),
        route: 'reports.vat-returns.review.rate-transactions',
    },
])

onMounted(() => {
    executeVatReturn({
        params: {
            id: route.params.id,
        },
    }).then(() => {
        executeViewVatReturn({
            data: {
                start_date: startDate.value,
                end_date: endDate.value,
            },
        })
    })
})
</script>
